<template>
  <div class="space-y-10">
    <!-- pageTitle -->
    <div class="pageTitle">
      <h1>{{ $route.name }}</h1>
      <h3>{{ $route.meta.description }}</h3>
    </div>
    <!-- content -->
    <div>
      <RetailPackages :items="experiences" />
    </div>
  </div>
</template>

<script>
import RetailPackages from "@/components/RetailPackages";
export default {
  components: { RetailPackages },
  data: () => ({
    categoryToFilter: "kOqODMn4khYnFjYGMaok",
  }),
  computed: {
    experiences() {
      let items = [];
      this.$store.state.retailPackages.forEach((i) => {
        let categoryFound = i.packageCategories.some(
          (i) => i == this.categoryToFilter
        );
        if (categoryFound) items.push(i);
      });
      return items;
    },
  },
};
</script>
